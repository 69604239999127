<template>
  <div>
    <div class="relative" v-if="loggedIn">
      <div class="absolute w-full pt-3 pb-32 sm:py-16 text-center text-purple-600 font-logo banner">
        <div class="max-w-7xl mx-auto">
          <router-link to="/">
            <img class="w-32 mx-auto sm:mx-0 sm:w-64 sm:pl-5" src="../assets/images/catlia_logo.png" >
          </router-link>
        </div>
      </div>

      <div class="relative pointer-events-none pt-12 pb-4 sm:pt-32 sm:pb-6">

      </div>

      <div class="relative">
        <div class="max-w-7xl mx-auto flex flex-col sm:flex-row sm:flex-wrap text-gray-900 justify-between">
          <div class="flex">
            <div class="anim-fast flex flex-wrap px-1 lg:px-2 content-center py-2 bg-white border-t-4 border-l-4 border-r-4 border-gray-200 rounded-t-xl">
              <div class="anim-fast px-2 lg:px-4 text-purple-700 font-thin font-body text-base lg:text-xl tracking-tighter">
                Welcome, <span class="pl-2">{{username}}</span> #{{usercode}}
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="anim-fast flex flex-wrap px-1 lg:px-2 content-center bg-white border-t-4 border-l-0 sm:border-l-4 border-r-4 border-gray-200 rounded-tr-xl sm:rounded-t-xl">
              <div class="anim-fast px-1 lg:px-3 flex content-center text-purple-500 hover:text-purple-700 font-body text-xl opacity-100 hover:opacity-50">
                <img class="object-contain" src="../assets/images/icons/mail.png" />
              </div>
              <div class="anim-fast px-1 lg:px-3 flex content-center text-purple-500 hover:text-purple-700 font-body text-xl opacity-100 hover:opacity-50">
                <img class="w-10/12 object-contain" src="../assets/images/icons/notif.png" />
              </div>
              <div class="anim-fast pl-1 lg:px-2 flex content-center items-center text-purple-500 hover:text-purple-700 font-hairline tracking-tighter font-body text-xl opacity-100 hover:opacity-50">
                <img class="pr-1 lg:pr-2 w-16 object-contain" src="../assets/images/icons/lunar_coin.png" /> 100
              </div>
              <div class="anim-fast pl-2 lg:pl-2 lg:pr-2 flex content-center items-center text-purple-500 hover:text-purple-700 font-hairline tracking-tighter font-body text-xl opacity-100 hover:opacity-50">
                <img class="pr-1 lg:pr-2 object-contain" src="../assets/images/icons/crown_coin2.png" /> 10,000 
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative border-b-4 border-gray-100">

      </div>

      <div class="relative bg-white border-b-4 border-purple-300">
        <div class="max-w-7xl mx-auto flex flex-wrap py-2 text-gray-900 justify-between">
          <div class="flex flex-wrap px-1 md:px-2 content-center">
            <router-link class="px-2 md:px-4 text-purple-700 hover:text-purple-400 font-body text-lg" to="/battle">Battle</router-link>
            <router-link class="px-2 md:px-4 text-purple-700 hover:text-purple-400 font-body text-lg" to="/cattery">Cattery</router-link>
            <router-link class="px-2 md:px-4 text-purple-700 hover:text-purple-400 font-body text-lg" to="/worldmap">World Map</router-link>
            <router-link class="px-2 md:px-4 text-purple-700 hover:text-purple-400 font-body text-lg" to="/forum">Forum</router-link>
            <router-link class="px-2 md:px-4 text-purple-700 hover:text-purple-400 font-body text-lg" to="/trades">Trades</router-link>
          </div>
          <div class="flex flex-wrap px-1 md:px-2 content-center">
            <button @click="logout" class="px-1 md:px-2 text-purple-700 hover:text-purple-100 font-body text-lg">Logout</button>
          </div>
        </div>
      </div>

      <div class="p-1 sm:p-5"></div>
      
      <!-- <div class="font-logo opacity-0">test</div> -->
    </div>
    <div class="" v-if="!loggedIn">
      <div class="flex flex-wrap py-4 text-gray-900 justify-between max-w-7xl mx-auto">
        <div class="w-32 md:w-64">
          <router-link to="/">
           <img class="w-full pt-2 sm:pt-3 md:pt-5 pl-3 sm:pl-5" src="../assets/images/catlia_logo.png" >
          </router-link>
        </div>
        <div class="flex flex-wrap px-1 md:px-2 content-center" v-if="false">
          <router-link class="hidden sm:block px-2 md:px-8 hover:text-purple-500 font-body" to="/news">News</router-link>
          <router-link class="hidden sm:block px-2 md:px-8 hover:text-purple-500 font-body" to="/cattery">Community</router-link>
          <router-link class="hidden sm:block px-2 md:px-8 hover:text-purple-500 font-body" to="/map">Shop</router-link>
        </div>
        <div class="flex flex-wrap pl-1 pr-5 content-center" v-if="dev">
          <div class="bg-purple-500 hover:bg-white rounded anim max-h-1/2 text-white hover:text-purple-500">
            <router-link class="pb-2 px-2 font-body" to="/signin">Sign In</router-link>
          </div>

          <div class="bg-purple-500 hover:bg-white rounded anim max-h-1/2 text-white hover:text-purple-500">
            <router-link class="pb-2 px-2 font-body" to="/signup">Sign Up</router-link>
          </div>
          
          <!-- <nuxt-link class="px-1 md:px-2 hover:text-purple-500" to="/login/">Login</nuxt-link> -->
          <!-- <nuxt-link class="px-1 md:px-2 hover:text-purple-500" to="/register/">Register</nuxt-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState(['loggedIn', 'username', 'usercode']),
  data() {
    return {
      dev: process.env.NODE_ENV == "development"
      //dev: true
    }
  },
  methods: {
    logout() {      
      this.$store.commit('logout')
      this.$cookies.remove('tokenId')
      this.$cookies.remove('usercode')
      this.$cookies.remove('username')
      this.$cookies.remove('expiry')
      
      if(this.$route.path == '/'){
        this.$forceUpdate()
      } else {
        this.$router.push({
          path: '/',
        })
      }
    },
  }
}
</script>

<style>
.banner {
  background: #B9B3F8;
  /*background-image: url("../../public/banner.png");*/
  /*background-image: url("../../public/banner2.png");*/
  /*background-image: url("../../public/banner3.jpg");*/
  /*background-image: url("../../public/banner4.png");*/
  background-image: url('~@/assets/images/banner.png');
}
</style>
