<template>
  <div class="editor">
    <div class="flex" v-if="editable">
        <button @click="toggleEditMode" class="rounded-lg py-1 px-1 md:px-5 text-red-500">
            {{editMode ? 'Finish' : 'Edit'}}
        </button>
        <button @click="exportJSON" v-if="editMode" class="rounded-lg bg-black py-1 px-1 md:px-5 text-red-500">
            Export
        </button>
        <button v-if="editMode" @click="setStyle('font-heading text-3xl md:text-4xl')" class="rounded-lg py-1 px-1 md:px-5 text-red-500">
            H1
        </button>
        <button v-if="editMode" @click="setStyle('font-body text-lg md:text-xl')" class="rounded-lg py-1 px-1 md:px-5 text-red-500">
            H2
        </button>
        <button v-if="editMode" @click="setStyle('font-body text-base md:text-lg')" class="rounded-lg py-1 px-1 md:px-5 text-red-500">
            H3
        </button>
        <button v-if="editMode" @click="setStyle('font-body text-sm md:text-base')" class="rounded-lg py-1 px-1 md:px-5 text-red-500">
            Body Font
        </button>
        <button v-if="editMode" @click="embedImg()" class="rounded-lg py-1 px-1 md:px-5 text-red-500">
            Embed Image
        </button>
    </div>

    <div class="">
        <div class="" v-for="(i, index) in elements" :key="index">
            <div :id="'Line'+r+index" :ref="'Line'+r+index" :class="i.style+' bg-transparent focus:bg-purple-100 outline-none'" v-if="i.type == 'text'" v-text="i.value" :contenteditable="editMode" @blur="update($event, index)" @focus="focus=index" @keydown="key($event, index, i.style, i.type)" >
            
            </div>

            <div v-if="!editMode && i.value.length ==0" :class="i.style">
                </br>
            </div>

            <div class="bg-transparent focus:bg-purple-100 outline-none" :id="'Line'+r+index" :ref="'Line'+r+index" v-if="i.type == 'img'" :contenteditable="editMode" @focus="focus=index" @keydown="key($event, index, i.style, i.type)">
                <img :src="i.value" :class="i.style" />
            </div>

        </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    //EditorContent,
    //EditorMenuBar,
  },
  props: ['json'],
  data() {
    return {
      exportedJson : '',
      editable: process.env.NODE_ENV == "development",
      editMode: false,
      elements: JSON.parse(this.json),
      focus: null,
      r: Math.random()
    }
  },
  mounted(){
  },
  methods: {
    embedImg(){
        if(!this.editMode) return

        if(this.focus != null){
            var src = prompt("Enter image url from blackblaze!", "");
            if(!src.startsWith("http")){
                src = require('../assets/images/'+src)
            }

            this.elements.splice(this.focus+1, 0, { type: 'img', value:src, style: ''} )
        }
                    
    },
    exportJSON(){
        console.log(JSON.stringify(this.elements))
    },
    setStyle(style){
        if(!this.editMode) return
        
        if(this.focus != null){
            this.elements[this.focus].style = style
            this.$nextTick(() => this.$refs['Line'+this.r+this.focus][0].focus())
        }
    },
    setCursorPosition(el,id) {
        var el = document.getElementById('Line'+this.r+id);
        var range = document.createRange();
        var sel = window.getSelection();
        range.setStart(el, el.innerText.length > 0 ? 1 : 0);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
    },
    toggleEditMode(){
        this.editMode = !this.editMode
    },
    update(e, id){
        if(e != null && this.elements[id] != null){
            this.elements[id].value = e.target.innerText
        }
        else{
            e.preventDefault()
        }
    },
    key(e, id, style, type){
        if(type == 'img' && (e.keyCode != 13 && e.keyCode != 8 && e.keyCode != 46)){
            e.preventDefault() 
            return
        }
        
        if(e.keyCode === 13){
            this.elements.splice(id+1, 0, { type: 'text', value:'', style: style} )
            this.$nextTick(() => this.$refs['Line'+this.r+(id+1)][0].focus())
            
            e.preventDefault()
        }

        if((e.keyCode === 8 || e.keyCode === 46) && e.target.innerText == 0){
            if(id > 0){
                this.elements.splice(id, 1)
                this.$nextTick(() => {
                    this.setCursorPosition(this.$refs['Line'+this.r+(id-1)][0], id-1);
                })
                e.preventDefault()
            }
        }
      }
  },
}
</script>

<style>
</style>