import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function parseJwt (token) {
  
  
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export function createStore(clientRendered) {
  return new Vuex.Store({
    state: process.env.NODE_ENV == "development" ? {
      loggedIn: clientRendered && window.localStorage.getItem("token") != null,
      cookie: 'No cookie',
      signUpEmail: '',
      loading: false,
      username: clientRendered && window.localStorage.getItem("token") != null ? parseJwt(window.localStorage.getItem("token")).username: '',
      usercode: clientRendered && window.localStorage.getItem("token") != null ? parseJwt(window.localStorage.getItem("token")).sub: '',
      lureDate: undefined,
      lureFind: {Image: 'base1-sm', Type:'kitten', Gender: false, Name: "Sunny"},
      lureFound: false
    } :
    {
      loggedIn: false,
      cookie: 'No cookie',
      signUpEmail: '',
      loading: false,
      username: '',
      usercode: 0
    },
    mutations: {
      login(state, req){
        state.loggedIn = true

        window.localStorage.setItem('token', req.token)
        state.username = req.username
        state.usercode = req.id


      },
      logout(state){
        state.loggedIn = false
        window.localStorage.removeItem("token")
      },
      signUpEmail(state, email){
        state.signUpEmail = email
      },
      setLoading(state, loading){
        state.loading = loading
      },
      setLure(state, lureDate){
        state.lureDate = new Date(new Date().getTime() + (60000/30));
        state.lureFound = false;
      },
      setCookie(state, cookie){
        state.cookie = cookie

        if(cookie.includes('tokenId')){
          state.loggedIn = true
        }
      },
    },
    actions: {},
    modules: {}
  })
}
