<template>
  <div>
    <editor-content
      class="editor outline-none min-h-32 sm:text-lg font-body"
      :editor="editor"
    />
    <editor-menu-bar :editor="editor" v-if="this.editable" v-slot="{ commands, isActive }">
      <div class="pt-3 sm:pt-5 md:pt-10 flex justify-evenly flex-wrap sm:block">
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.bold(),
          }"
          @click="commands.bold"
        >
          B
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold italic bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.italic(),
          }"
          @click="commands.italic"
        >
          I
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold underline bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.underline(),
          }"
          @click="commands.underline"
        >
          U
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold line-through bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.strike(),
          }"
          @click="commands.strike"
        >
          S
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.paragraph(),
          }"
          @click="commands.paragraph"
        >
          ¶
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.heading(
              { level: 1 }
            ),
          }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.heading(
              { level: 2 }
            ),
          }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.heading(
              { level: 3 }
            ),
          }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.bullet_list(),
          }"
          @click="commands.bullet_list"
        >
          •
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.ordered_list(),
          }"
          @click="commands.ordered_list"
        >
          1.
        </button>
        <button
          class="mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          :class="{
            'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.blockquote(),
          }"
          @click="commands.blockquote"
        >
          “
        </button>
        <button
          class="mr-2 mb-2 py-1 px-2 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          @click="showImagePrompt(commands.image)"
        >
          Image
        </button>
        <button
          class="mr-2 mb-2 py-1 px-2 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          @click="commands.undo"
        >
          Undo
        </button>
        <button
          class="mr-2 mb-2 py-1 px-2 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple"
          @click="commands.redo"
        >
          Redo
        </button>
      </div>
    </editor-menu-bar>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Placeholder,
  Image,
} from "tiptap-extensions";

export default {
  components: {
    EditorMenuBar,
    EditorContent,
  },
  props: ['json','editable'],
  watch: {
    json: {
      handler(n, o) {
        this.editor.setContent(n)
      },
      deep: true
    }
  },
  data() {
    var jsonContent = null
    
    if(this.json != null){
      try{
        if(this.json.type == "doc" && this.json.content.length > 0){
          jsonContent = this.json
        }
      }catch(e){
        //jsonContent = "Failed to load comment!"
      }
    }
    
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Code(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyEditorClass: "is-editor-empty",
            emptyNodeClass: "is-empty",
            emptyNodeText: "Write your comment here ...",
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
          new Image(),
        ],
        editable: this.editable,
        content: jsonContent != null ? jsonContent : ``,
        onUpdate: ({ getJSON }) => {
          this.$emit("commentJSON", getJSON());
        },
      }),
    };
  },
  methods:{
    showImagePrompt(command) {
      const src = prompt('Enter the url of your image here')
      if (src !== null) {
        command({ src })
      }
    },
    clear(){
      this.editor.setContent({
        "type": "doc",
        "content": [
          {
            "type": "paragraph"
          }
        ]
      })
    },
    setContent(content){
      this.editor.setContent(content)
    },
    toggleEditable(value){
      this.editor.setOptions({
        editable: value
      })
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style>
.editor p.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #CBD5E0;
  pointer-events: none;
  height: 0;
  font-style: italic;
}

.ProseMirror-focused {
  @apply outline-none;
}

.ProseMirror blockquote {
  @apply border-l-8 pl-3 italic text-gray-700;
  @apply border-purple-100;
}

.ProseMirror ul {
  @apply list-disc;
  @apply pl-5;
}

.ProseMirror ol {
  @apply list-decimal;
  @apply pl-4;
}

.ProseMirror img {
  @apply mx-auto;
  @apply rounded-xl
}

.ProseMirror h1 {
  @apply text-2xl;
}

@media (min-width: 640px) {
  .ProseMirror h1 {
    @apply text-3xl;
  }
}

.ProseMirror h2 {
  @apply text-xl;
}

@media (min-width: 640px) {
  .ProseMirror h2 {
    @apply text-2xl;
  }
}

.ProseMirror h3 {
  @apply text-lg;
}

@media (min-width: 640px) {
  .ProseMirror h3 {
    @apply text-xl;
  }
}
</style>