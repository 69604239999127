<template>
  <div>
    <div class="bg-white p-12 md:p-20 border-b-4 border-purple-300"></div>
    
    <div class="w-full py-5 sm:py-10 md:py-15 lg:py-20 text-center text-purple-900 text-lg sm:text-2xl md:text-3xl lg:text-4xl font-thin font-sans flex justify-center">
      <div class="bg-white p-2 rounded-xl shadow-inner border-4 border-purple-300">
        Sign up and let the fun begin :D
      </div>
    </div>
    
    <div class="container relative h-full px-3 sm:px-5 mx-auto sm:pt-5 pb-10 flex w-full flex-wrap sm:flex-no-wrap justify-between">
      <div class="bg-white px-5 pt-5 pb-5 sm:pb-20 rounded-xl shadow-inner border-4 border-purple-300 flex w-full flex-wrap sm:flex-no-wrap justify-between">
      <div class="w-full sm:pr-3 py-5 sm:border-r">
        <InputField label="Email Address" type='email' :issue="emailIssue" :initial='initialEmail' placeHolder="catlia@example.com" :changed="checkEmail" />
        <div class="pt-5"></div>
        <InputField label="Username" type='text' :issue="usernameIssue" regex="^[A-Za-z0-9\s]+$" placeHolder="catlia" :changed="checkUsername" />
        <div class="pt-5"></div>
        <InputField label="Password" type='password' :issue="passwordIssue" placeHolder="*****" :changed="checkPassword"  />
        <div class="pt-5"></div>
        <InputField label="Kitten Name" type='text' :issue="kittenNameIssue" regex="^[A-Za-z0-9\s]+$" placeHolder="Cutie <3" :changed="checkKittenName" />
      </div>
      <div class="pt-5 sm:pt-0 w-full sm:pl-3">
        <div class="font-heading text-2xl text-center text-gray-700" :class="{'text-green-500' : this.starter != -1, 'text-red-500' : this.starterRequired}">
          Choose a kitten to start your journey with.
        </div>
        <div class="pt-2 mt-2 mb-2 mx-auto flex justify-between">
          <button :disabled="starter === 0" :class="{'border-green-500' : this.starter === 0, 'border-red-300' : this.starterRequired}" class="rounded-lg border p-1 sm:p-2 focus:outline-none" @click="select(0)">
            <img src="../assets/images/base1-sm.jpg" class="w-full" />
          </button>
          <button :disabled="starter === 1" :class="{'border-green-500' : this.starter === 1, 'border-red-300' : this.starterRequired}" class="rounded-lg border p-1 sm:p-2  ml-1 sm:ml-2 focus:outline-none" @click="select(1)">
            <img src="../assets/images/base2-sm.jpg" class="w-full" />
          </button>
          <button :disabled="starter === 2" :class="{'border-green-500' : this.starter === 2, 'border-red-300' : this.starterRequired}" class="rounded-lg border p-1 sm:p-2 ml-1 sm:ml-2 focus:outline-none" @click="select(2)">
            <img src="../assets/images/base3-sm.jpg" class="w-full" />
          </button>
        </div>
        <div class="pt-5 sm:pt-2 text-center font-body text-sm text-red-500">
          {{error}}
        </div>
        <div class="hidden sm:block absolute bottom-0 w-1/2 px-6 pb-16">
          <div class="w-full pr-8 flex justify-end">
            <button @click="signUp" class="bg-gradient-purple bg-2nd-half hover:bg-1st-half anim shadow-sm hover:shadow-inner py-3 px-8 mt-3 text-lg text-white font-body font-semibold rounded-xl outline-none focus:outline-none focus:shadow-outline-purple">
              Create Account
            </button>
          </div>
        </div>
        <div class="flex sm:hidden w-full justify-center pt-5 pb-5">
          <button @click="signUp" class="bg-gradient-purple bg-2nd-half hover:bg-1st-half anim shadow-sm hover:shadow-inner py-3 px-8 mt-3 text-lg text-white font-body font-semibold rounded-xl outline-none focus:outline-none focus:shadow-outline-purple">
            Create Account
          </button>
        </div>
      </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import InputField from '@/components/InputField'
import gql from "graphql-tag";

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email) && email.length < 128;
}

export default {
  transition: 'bounce',
  data(){
    return {
      email: this.$store.state.signUpEmail,
      username: '',
      password: '',
      kittenName: '',
      starter: -1,
      starterRequired: false,
      emailIssue: '',
      usernameIssue: '',
      passwordIssue: '',
      kittenNameIssue: '',
      signingUp: false,
      error: ''
    }
  },
  computed: {
    initialEmail() {
      return this.$store.state.signUpEmail
    }
  },
  components: {
    InputField
  },
  methods:{
    async signUp(){
      if(this.signingUp) return
      this.signingUp = true;
      this.$store.commit('setLoading', true)
      
      var validEmail = this.checkEmail(this.email)
      var validUser = this.checkUsername(this.username)
      var validPswd = this.checkPassword(this.password)
      var validKitten = this.checkKittenName(this.kittenName)
      var validStarter = this.starter != -1

      if(!validStarter)
        this.starterRequired = true

      if(validEmail && validUser && validPswd && validKitten && validStarter){
          //this.axios.post('https://api.catliadotnet.workers.dev/signup', {
          /*this.axios.post('http://192.168.0.2:7071/signup', {
            "email": this.email,
            "username": this.username,
            "password": this.password,
            "kitten": this.starter,
            "kittenName": this.kittenName
          }).then((data) => {
            console.log(data)
            this.error = ''
            this.signingUp = false
            this.$store.commit('setLoading', false)
            this.$store.commit('login')
            this.$cookies.set('token', data.data)
            this.$router.push({
              path: '/',
            })
          }).catch((err) => {
            this.signingUp = false
            this.$store.commit('setLoading', false)
            if( err.response ){
              this.error = err.response.data
            }else {
              this.error = err
            }
          })*/

          this.$apollo.mutate({
            mutation: gql`
              mutation ($email: String!, $username: String!, $password: String!, $kitten: Int!, $kittenName: String!) {
                catlia_signup(check: "", email: $email, password: $password, username: $username, kitten: $kitten, kittenName: $kittenName) {
                  id
                  token
                }
              }`,
              variables: {
                email: this.email,
                username: this.username,
                password: this.password,
                kitten: this.starter,
                kittenName: this.kittenName
              },
          }).then((data) => {
            console.log(data)
            this.error = ''
            this.signingUp = false
            this.$store.commit('setLoading', false)
            this.$token = data.data.catlia_signup.token
            this.$cookies.set('token', data.data.catlia_signup.token)
            this.$store.commit('login', {username: this.username, id:data.data.catlia_signup.id, token:data.data.catlia_signup.token})
            this.$router.push({
              path: '/',
            })
          }).catch((err) => {
              this.signingUp = false
              this.$store.commit('setLoading', false)
            if( err.response ){
              console.error(err)
              this.error = err.response.data
            }else {
              this.error = err
            }
          });
      }
    },
    select(id){
      this.starter = id
      this.starterRequired = false
    },
    checkEmail(value){
      this.email = value

      if(!validateEmail(value) || value.length == 0){
        this.emailIssue = 'please enter a valid email address.'
        return false
      } else {
        this.emailIssue = ''
        return true
      }
    },
    checkUsername(value){
      this.username = value

      if(value.length == 0){
        this.usernameIssue = 'please enter a username.'
        return false
      } else if(value.length > 128){
        this.usernameIssue = 'please enter a username less than 128 characters.'
        return false
      } else {
        this.usernameIssue = ''
        return true
      }
    },
    checkPassword(value){
      this.password = value

      if(value.length < 12 ){
        this.passwordIssue = 'password must be atleast 12 characters.'
        return false
      } else if(value.length > 128){
        this.passwordIssue = 'password must be less than 128 characters.'
        return false
      }else {
        this.passwordIssue = ''
        return true
      }
    },
    checkKittenName(value){
      this.kittenName = value

      if(value.length == 0){
        this.kittenNameIssue = 'please enter a kitten name.'
        return false
      } else {
        this.kittenNameIssue = ''
        return true
      }
    }
  }
}
</script>

<style>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-out .3s;
}
@keyframes bounce-in {
  0% { transform: scale(0.8); opacity: 0;}
  50% { transform: scale(1.1); opacity: 0.8; }
  95% { transform: scale(1); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}
@keyframes bounce-out {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.8; }
  95% { transform: scale(0.8); opacity: 0; }
  100% { transform: scale(0.8); opacity: 0; }
}
</style>