import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SignUp from '../views/SignUp.vue'
import SignIn from '../views/SignIn.vue'
import Vue from 'vue'

Vue.use(VueRouter)

export const createRouter = () => {
  var routes = [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    { path: '*', redirect: '/' }
  ]

  if(process.env.NODE_ENV == "development"){
    routes.push(
      {
        path: '/signup',
        name: 'signup',
        component: SignUp
      },
      {
        path: '/signin',
        name: 'signin',
        component: SignIn
      },
      {
        path: '/worldmap',
        name: 'worldmap',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/WorldMap.vue')
      },
      {
        path: '/worldmap/:id',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/SparkleMap.vue')
      },
      {
        path: '/battle',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/Battle.vue')
      },
      {
        path: '/cattery',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/Cattery.vue')
      },
      {
        path: '/cattery/:user',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/Cattery.vue')
      },
      {
        path: '/post/new/:id',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/PostNew.vue')
      },
      {
        path: '/post/:id',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/Post.vue')
      },
      {
        path: '/forum',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/Forum.vue')
      },
      {
        path: '/forum/:id',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/PostCategory.vue')
      },
      {
        path: '/trades',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "game" */ '../views/Trade.vue')
      },
    )
  }

  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
}
