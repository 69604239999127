<template>
  <div id="app" class="min-h-screen text-3xl cat-toes" v-bind:class="{ ' bg-purple-200' : loggedIn}">
    <div class="relative text-base">
      <Loading class="absolute" />
      <NavBar class="" v-if="loggedIn" />
      <div>
        <NavBar class="absolute z-50 w-full" v-if="!loggedIn" />
        <router-view v-if="!loggedIn && ($router.currentRoute.path =='/' || $router.currentRoute.path =='/signin' || $router.currentRoute.path =='/signup') " />
        
        <div class="w-full pt-32 pb-10 px-5" v-if="!loggedIn && ($router.currentRoute.path !='/' && $router.currentRoute.path != '/signin' && $router.currentRoute.path != '/signup') ">
          <div class="pt-10"></div>
          <router-view  class="w-full p-4 bg-white border-4 border-purple-300 rounded-xl" />
        </div>

        <div class="flex max-w-7xl mx-auto px-2 md:px-4" v-if="loggedIn"> 
          <div class="hidden md:block md:w-1/4 lg:w-1/5 text-center pr-4">
            <div class="bg-white rounded-xl border-4 border-purple-300 flex flex-wrap">
              <button class="w-full outline-none focus:outline-none p-5">Button 1</button>
              <button class="w-full outline-none focus:outline-none rounded-t-xl border-t-2 border-gray-200 p-5">Button 1</button>
              <button class="w-full outline-none focus:outline-none rounded-t-xl border-t-2 border-gray-200 p-5">Button 1</button>
              <button class="w-full outline-none focus:outline-none rounded-t-xl border-t-2 border-gray-200 p-5">Button 1</button>
              <button class="w-full outline-none focus:outline-none rounded-t-xl border-t-2 border-gray-200 p-5">Button 1</button>
              <button class="w-full outline-none focus:outline-none rounded-t-xl border-t-2 border-gray-200 p-5">Button 1</button>
            </div>
          </div>
          <div class="w-full pb-10">
            <router-view class="w-full p-2 sm:p-4  bg-white border-4 border-purple-300 rounded-xl"/>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import Loading from '@/components/Loading'
import { mapState } from 'vuex'

export default {
  components: {
    NavBar,
    Loading
  },
  computed: mapState(['loggedIn']),
  created(){
    console.log(this.$router.currentRoute.path)
  }
}

</script>

<style>
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.cat-toes {
	background: #B9B3F8;
  /*background-image: url("../public/catlia_bg.png");*/
  background-image: url('~@/assets/images/background.png');
  background-position: 0.4em 0.6em;
	background-size: 4.0em 4.0em;
}

* {
  margin: 0;
  padding: 0;
}

.anim {
  transition: 0.2s;
}

.anim-fast {
  transition: .1s;
}

.anim-slow {
  transition: 1s;
}

.bg-gradient-purple-right {
    background-image: linear-gradient(to right, theme(colors.purple.400), theme(colors.purple.600));
}

@variants hover, disabled {  
  .bg-gradient-purple {
    background-image: linear-gradient(to bottom, theme(colors.purple.200), theme(colors.purple.400), theme(colors.purple.600));
    background-size: 200% 200%;
  }

  .bg-gradient-purple-secondary {
    background-image: linear-gradient(to bottom, theme(colors.gray.300), theme(colors.gray.400), theme(colors.gray.500));
    background-size: 200% 200%;
  }

  .bg-1st-half {
    background-position: 0% 50%;
  }

  .bg-2nd-half {
    background-position: 50% 100%;
  }
  
  .bg-gradient-purple-light {
      background-image: linear-gradient(to bottom, theme(colors.purple.400), theme(colors.purple.600));
  }

  .bg-gradient-gray {
      background-image: linear-gradient(to bottom, theme(colors.gray.400), theme(colors.gray.500));
  }
}
</style>
