<template>
  <div class="">
    <div class="bg-white p-12 md:p-20 border-b-4 border-purple-300">

    </div>
    <div class="px-2 py-5 sm:py-10 md:py-15 lg:py-20 flex justify-center">
      <div class="bg-white p-2 rounded-xl shadow-inner border-4 border-purple-300">
        <h1 class="text-purple-900 mx-5 sm:mx-auto text-lg sm:text-2xl md:text-3xl lg:text-4xl font-thin text-center font-sans">Catlia has missed you, login to join the fun again <3</h1>
      </div>
      
    </div>
    <div class="max-w-xl px-2 sm:py-5 mx-auto">
      <div class="bg-white p-5 rounded-xl shadow-inner border-4 border-purple-300">
        <InputField label="Email Address" type='email' :issue="emailIssue" placeHolder="catlia@example.com" :changed="checkEmail" />
        <div class="pt-5"></div>
        <InputField label="Password" type='password' :issue="passwordIssue" placeHolder="********" :changed="checkPassword" />
        <div class="pt-5"></div>
        <div class="pt-2 text-center font-body text-sm text-red-500">
            {{error}}
          </div>
        <div class="w-full flex justify-center sm:justify-end">
          <button @click="login" :disabled="loading" class="bg-gradient-purple bg-2nd-half hover:bg-1st-half anim shadow-sm hover:shadow-inner py-3 px-12 mt-3 text-lg text-white font-body font-semibold rounded-xl outline-none focus:outline-none focus:shadow-outline-purple">{{buttonText}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/InputField'
import gql from "graphql-tag";

function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email) && email.length < 128;
}

export default {
  components: {
    InputField
  },
  data(){
    return {
      emailIssue: '',
      passwordIssue: '',
      error: '',
      loading: false,
      buttonText: 'Sign In'
    }
  },
  methods: {
    checkEmail(value){
      this.email = value

      if(!validateEmail(value) || value == null || value.length == 0){
        this.emailIssue = 'please enter a valid email address.'
        return false
      } else {
        this.emailIssue = ''
        return true
      }
    },
    checkPassword(value){
      this.password = value

      if(value == null || value.length < 12 ){
        this.passwordIssue = 'password must be atleast 12 characters.'
        return false
      } else if(value.length > 128){
        this.passwordIssue = 'password must be less than 128 characters.'
        return false
      }else {
        this.passwordIssue = ''
        return true
      }
    },
    login(){
      if(this.loading) return
      this.loading = true
      this.$store.commit('setLoading', true)
      
      var validEmail = this.checkEmail(this.email)
      var validPswd = this.checkPassword(this.password)
      
      if(validEmail && validPswd){
          this.$apollo.mutate({
            mutation: gql`
              mutation ($email: String!, $password: String!) {
                catlia_signin(check: "", email: $email, password: $password) {
                  id
                  token
                  username
                }
              }`,
              variables: {
                email: this.email,
                password: this.password,
              },
          }).then((data) => {
            console.log(data)
            this.error = ''
            this.loading = false
            this.$store.commit('setLoading', false)
            this.$token = data.data.catlia_signin.token
            this.$cookies.set('token', data.data.catlia_signin.token)
            this.$store.commit('login', data.data.catlia_signin)
            this.$router.push({
              path: '/',
            })
          }).catch((err) => {
            this.loading = false
            this.$store.commit('setLoading', false)
            if( err.response ){
              this.error = err.response.data
            }else {
              this.error = err
            }
          })
      } else {
        this.loading = false
        this.$store.commit('setLoading', false)
      }
    }
  }
}
</script>

<style>

</style>