import Vue from 'vue'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from 'vuex-router-sync'

import VueApollo from "vue-apollo";

import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from 'apollo-link'

import 'typeface-petit-formal-script'
import 'typeface-bellefair'
import 'typeface-open-sans'


export function createApp(clientRendered) {
  Vue.config.productionTip = false

  //let token = localStorage != null ? localStorage.getItem('token') : null;
  let token = ''
  if(clientRendered){
    token = localStorage.getItem('token');
    Object.defineProperty(Vue.prototype, '$token', {
      get() { return token },
      set(value) { token = value },
    })
  }

  const httpLink = new HttpLink({ uri: 'https://api.catlia.net/v1/graphql' })

  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: token ? { authorization: `Bearer ${token}` } : {}
    })
    return forward(operation)
  })

  const link = middlewareLink.concat(httpLink)

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      addTypename: true
    })
  });

  const router = createRouter()
  const store = createStore(clientRendered)

  // sync so that route state is available as part of the store
  sync(store, router)

  Vue.use(VueCookies)
  Vue.use(VueAxios, axios)
  Vue.use(VueApollo);

  const apolloProvider = new VueApollo({
    defaultClient: client,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      }
    }
  })

  // create the app instance, injecting both the router and the store
  const app = new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App)
  })

  // expose the app, the router and the store.
  return { app, router, store }
}
