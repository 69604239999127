<template>
  <div class="w-full h-full z-50" :class="{'pointer-events-none': !loading, 'pointer-events-auto' : loading}" >
    <div class="w-full h-full flex justify-center">
      <div class="self-center font-heading text-6xl backdrop-blurless anim-slow rounded-xl shadow-md py-10 px-20" :class="{'opacity-0' : !loading, 'pointer-events-none': !loading, 'opacity-100' : loading, 'pointer-events-auto' : loading}" v-if="loading" >Loading...</div>
    </div>
  </div>
</template>

<script>
export default {
  computed:{
    loading(){
      return this.$store.state.loading
    }
  }
}
</script>

<style>
.blur {
  backdrop-filter: blur(10px);
}
</style>
