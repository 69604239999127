<template>
  <div>
    <label class="font-semibold font-body text-sm sm:text-base" :class="{'text-gray-600' : !this.checked, 'text-red-500' : this.issue.length != 0, 'text-green-500' : this.issue.length == 0 && this.checked }">{{label}}</label>
    <span  class="text-xs font-body" :class="{'text-white' : this.issue.length == 0, 'text-red-500' : this.issue.length != 0}"> - {{issue}}</span>
    <div class="mt-2 shadow-sm rounded-xl">
      <input v-model="value" class="p-3 w-full bg-gray-100 placeholder-gray-400 outline-none focus:outline-none rounded-xl shadow-inner border font-body " :class="{'border-gray-300 text-black focus:border-purple-300 focus:shadow-outline-purple' : !this.checked, 'border-red-400 text-red-500 focus:border-red-400 focus:shadow-outline-redlight' : this.issue.length != 0, 'border-green-400 text-green-500 focus:border-green-400 focus:shadow-outline-greenlight' : this.issue.length == 0 && this.checked }" :placeholder="placeHolder"  @input="valueChanged" :type="type" />
    </div>
  </div>
  
</template>

<script>
String.prototype.isMatch = function(r) {
  var regExp = new RegExp(r);
  return (this.match(regExp));
};

export default {
  props: {
    label: String,
    type: String,
    initial: String,
    valid: String,
    issue: String,
    placeHolder: String,
    regex: String,
    changed: Function,
  },
  data() {
    return {
      value: this.initial,
      checked: this.valid != null ? this.valid : false,
    }
  },
  mounted(){
    if(this.initial != null && this.initial.length > 0) {
      this.checked = true
      this.changed(this.initial)
    }
  },
  methods: {
    valueChanged(){
      if(this.regex != null){
        var result = (this.value.isMatch(this.regex))
        
        if(result == null && this.value != null && this.value.length > 0) {
          this.value = this.value.substring(0, this.value.length-1)
        }
      }

      this.checked = true
      this.changed(this.value)
    }
  }
}
</script>

<style>

</style>