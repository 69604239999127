var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('editor-content',{staticClass:"editor outline-none min-h-32 sm:text-lg font-body",attrs:{"editor":_vm.editor}}),(this.editable)?_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',{staticClass:"pt-3 sm:pt-5 md:pt-10 flex justify-evenly flex-wrap sm:block"},[_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.bold(),
        },on:{"click":commands.bold}},[_vm._v(" B ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold italic bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.italic(),
        },on:{"click":commands.italic}},[_vm._v(" I ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold underline bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.underline(),
        },on:{"click":commands.underline}},[_vm._v(" U ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold line-through bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.strike(),
        },on:{"click":commands.strike}},[_vm._v(" S ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.paragraph(),
        },on:{"click":commands.paragraph}},[_vm._v(" ¶ ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.heading(
            { level: 1 }
          ),
        },on:{"click":function($event){return commands.heading({ level: 1 })}}},[_vm._v(" H1 ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.heading(
            { level: 2 }
          ),
        },on:{"click":function($event){return commands.heading({ level: 2 })}}},[_vm._v(" H2 ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.heading(
            { level: 3 }
          ),
        },on:{"click":function($event){return commands.heading({ level: 3 })}}},[_vm._v(" H3 ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.bullet_list(),
        },on:{"click":commands.bullet_list}},[_vm._v(" • ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.ordered_list(),
        },on:{"click":commands.ordered_list}},[_vm._v(" 1. ")]),_c('button',{staticClass:"mr-2 mb-2 p-1 w-10 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",class:{
          'bg-purple-300 text-purple-100 hover:bg-purple-200 hover:text-white': isActive.blockquote(),
        },on:{"click":commands.blockquote}},[_vm._v(" “ ")]),_c('button',{staticClass:"mr-2 mb-2 py-1 px-2 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",on:{"click":function($event){return _vm.showImagePrompt(commands.image)}}},[_vm._v(" Image ")]),_c('button',{staticClass:"mr-2 mb-2 py-1 px-2 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",on:{"click":commands.undo}},[_vm._v(" Undo ")]),_c('button',{staticClass:"mr-2 mb-2 py-1 px-2 font-bold bg-purple-100 hover:bg-gray-200 rounded outline-none focus:outline-none focus:shadow-outline-purple",on:{"click":commands.redo}},[_vm._v(" Redo ")])])]}}],null,false,216480873)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }