<template>
  <div class="flex justify-center py-32">
    <div class="relative bg-purple-500 p-2 sm:p-4 rounded-full spin">
      <div class="text-white bg-white p-12 sm:p-32 rounded-full">
      </div>
      <div class="absolute bg-white p-6 -mt-6 sm:p-12 sm:-mt-12 rounded-full opacity-50">

      </div>
      
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.spin {
  animation-name: spin;
  animation-duration: 1250ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
</style>